/*----------------------------------------------
    ## Banner
----------------------------------------------*/

/* Parent Container */

.reserveBar {
  display: flex;
  justify-content: center;
  align-items: flex-start;
  position: absolute;
  top: 60%; 
  left: 50%; 
  transform: translateX(-50%); 
  width: 100%;
  z-index: 10; 
}

.cardMain {
  background: #fff;
  padding: 20px 30px;
  border-radius: 16px;
  box-shadow: 0 4px 16px rgba(0, 0, 0, 0.2);
  width: 65%;
  text-align: center;
  border: 1px solid #e0e0e0;
  position: relative;
  z-index: 20;
}


.ImageClass {
  background-color: #f5f5f5;
  height: 80vh;
  box-sizing: border-box;
  background-image: url("../../img/home-2/BackGroundImage.png");
  background-size: cover;
  background-position: center;
  background-repeat: no-repeat;
}

@media (max-width: 1024px) {
  .ImageClass {
    height: 70vh;
  }
}


// /* Card Container */
// .cardMain {
//   background: #fff;
//   padding: 20px 30px;
//   border-radius: 16px;
//   box-shadow: 0 4px 16px rgba(0, 0, 0, 0.2);
//   width: 65%;
//   // max-width: 1000px;
//   text-align: center;
//   border: 1px solid #e0e0e0;
// }

/* Tabs */
.tabs {
  display: flex;
  justify-content: space-between;
  margin-bottom: 20px;
  flex-wrap: wrap;
}

.tab {
  background: none;
  border: none;
  font-size: 16px;
  font-weight: 600;
  color: #333;
  padding: 10px 20px;
  cursor: pointer;
}
.placeholder-text {
  font-size: 14px;
  color: gray;
  margin-top: 4px;
}


.tab.active {
  color: #ff6f00;
  border-bottom: 2px solid #ff6f00;
}

/* Form Row */
.form-row {
  display: flex;
  align-items: center;
  gap: 10px;
  flex-wrap: wrap;
  margin-top: 20px;
  justify-content: left; 
}

/* Form Group (Updated for Input Fields) */
.form-group input {
  width: 100%;
  padding: 12px;
  font-size: 14px;
  border: 1px solid #ccc;
  border-radius: 5px;
  background: #f9f9f9;
  color: #333;
}

.form-group input:focus {
  border-color: #007bff;
  outline: none;
  box-shadow: 0 0 5px rgba(0, 123, 255, 0.5);
}

/* Button */
/* Styling for the "Get Rates" button */
.getRates {
  background-color: #ff6f00;
  color: #fff;
  padding: 10px 20px;
  border: none;
  border-radius: 50px; 
  font-size: 16px;
  font-weight: bold;
  cursor: pointer;
  transition: all 0.3s ease-in-out;
  box-shadow: 0 2px 6px rgba(0, 0, 0, 0.2); 
  margin-left: 20px; 
  white-space: nowrap; 
}

.getRates:hover {
  background-color: #e65c00;
  transform: scale(1.05);
}

/* Styling checkboxes */
input[type="checkbox"] {
  width: 16px;
  height: 16px;
  accent-color: #ff6f00;
  cursor: pointer;
  margin-right: 8px;
  vertical-align: middle;
}

/* Label styling */
label {
  font-size: 14px;
  color: #f66711;
  font-weight: 500;
  cursor: pointer;
  margin-right: 15px;
}

/* Aligning checkbox and input field */
.return-location-wrapper {
  display: flex;
  align-items: center;
  gap: 10px; 
  margin-bottom: 10px; 
}

/* Ensure "Get Rates" button and input fields are on the same line */
.date-and-button-wrapper {
  display: flex;
  align-items: center;
  gap: 10px;
  flex-wrap: nowrap;
}

.date-and-button-wrapper input {
  flex: 1; /* Allow date fields to take available space */
}

/* Responsive Adjustments */
@media (max-width: 768px) {
  .form-group input {
    font-size: 12px;
    padding: 10px;
  }

  .getRates {
    font-size: 14px;
    padding: 10px 15px;
  }


}

@media (max-width: 480px) {
  .reserveBar
  {
    padding: 0;
    height: 90vh;
  }
  .compact-fields
  {
    gap: 0 !important ;
  }
  .cardMain
  {
    width: 100%;
    bottom: 15%;
  }
  .form-row {
    flex-direction: row;
    gap: 15px;
  }

  .form-group input {
    font-size: 14px;
    padding: 12px;
    width: 100%;
  }

  .getRates {
    width: 100%;
  }
}

/* Form Row with Compact Fields */
.compact-fields {
  display: flex;
  justify-content: space-between;
  align-items: center;
  flex-wrap: wrap;
  gap: 10px;
}

.compact-fields .single-input-inner {
  flex: 1 1 18%;
  min-width: 120px;
}

/* Button Row */
.button-row {
  display: flex;
  justify-content: center;
  margin-top: 20px;
}

/* Checkbox Row */
.checkboxes-row {
  display: flex;
  justify-content: space-between;
  align-items: center;
  margin-top: 20px;
}

.checkbox-wrapper {
  display: flex;
  align-items: center;
  gap: 10px;
}
/* Standardized Field Styling */
.compact-fields .single-input-inner input,
.compact-fields .single-input-inner select,
.compact-fields .single-input-inner .Autocomplete__input {
  width: 100%; 
  padding: 10px; 
  border: 1px solid #ccc; 
  border-radius: 5px; 
  background: #f9f9f9; 
  color: #333; 
  font-size: 14px; 
  height: 40px; 
  box-sizing: border-box; 
  transition: border-color 0.3s ease, background-color 0.3s ease;
}
.compact-fields .single-input-inner2 input,
.compact-fields .single-input-inner2 select,
.compact-fields .single-input-inner2 .Autocomplete__input {
  width: 100%; 
  padding: 10px; 
  border: 1px solid #ccc; 
  border-radius: 5px; 
  background: #f9f9f9; 
  color: #333; 
  font-size: 14px; 
  height: 40px; 
  box-sizing: border-box; 
  transition: border-color 0.3s ease, background-color 0.3s ease;
}

.compact-fields .single-input-inner input:focus,
.compact-fields .single-input-inner select:focus,
.compact-fields .single-input-inner .Autocomplete__input:focus {
  border-color: #007bff;
  outline: none;
  box-shadow: 0 0 5px rgba(0, 123, 255, 0.5);
}

/* Fixing Select Field to Match Others */
.compact-fields .single-input-inner select {
  appearance: none; 
  -webkit-appearance: none; 
  -moz-appearance: none; 
}

/* Add a custom dropdown arrow */
.compact-fields .single-input-inner select:after {
  content: "";
  position: absolute;
  right: 10px;
  top: 50%;
  transform: translateY(-50%);
  border: 5px solid transparent;
  border-top-color: #333;
  pointer-events: none;
}

/* Consistent Widths */
.compact-fields .single-input-inner {
  flex: 1 1 18%; 
  min-width: 120px;
}


.polygen-title {
  clip-path: polygon(0 0, 100% 0%, 85% 100%, 0 100%);
  background-color: var(--main-color);
}
.polygen-title-opposite {
  clip-path: polygon(15% 0, 100% 0%, 85% 100%, 0% 100%);
  background-color: var(--main-color);
  padding: 0 30px !important;
}
.banner-area-2 {
  .banner-inner {
    padding: 343px 0 365px;
    position: relative;
    .btn-wrap {
      .btn-base {
        margin-right: 28px;

      }
    }
  }
  .slick-slider {
    .slick-active {
      .b-animate-1 {
        animation: 1.5s 0.2s bannerFadeInLeft both;
      }
      .b-animate-2 {
        animation: 1.5s 0.4s bannerFadeInLeft both;
      }
      .b-animate-3 {
        animation: 1.5s 0.6s bannerFadeInLeft both;
      }
      .b-animate-4 {
        animation: 1.5s 0.8s bannerFadeInLeft both;
      }
      .b-animate-5 {
        animation: 1.5s 0.9s bannerFadeInLeft both;
      }
    }
    .slick-prev {
      left: 10px;
      top: 52%;
      z-index: 9;
      width: 60px;
      height: 60px;
      border-radius: 50%;
      background: #fff;
      color: var(--main-color);
      padding: 18px;
      transition: all 0.2s linear;
      &:hover {
        background: var(--main-color);
        color: #fff;
      }
    }
    .slick-next {
      left: 10px;
      top: 59%;
      z-index: 9;
      width: 60px;
      height: 60px;
      border-radius: 50%;
      background: #fff;
      color: var(--main-color);
      padding: 18px;
      transition: all 0.2s linear;
      &:hover {
        background: var(--main-color);
        color: #fff;
      }
    }
  }
}
.banner-area-3 {
  margin-top: 80px;
  .banner-inner {
    padding: 100px 0;
    position: relative;
    .btn-wrap {
      .btn-base {
        margin-right: 28px;
      }
    }
  }
  .slick-slider {
    .slick-active {
      .b-animate-1 {
        animation: 1.5s 0.2s bannerFadeInLeft both;
      }
      .b-animate-2 {
        animation: 1.5s 0.4s bannerFadeInLeft both;
      }
      .b-animate-3 {
        animation: 1.5s 0.6s bannerFadeInLeft both;
      }
      .b-animate-4 {
        animation: 1.5s 0.8s bannerFadeInLeft both;
      }
      .b-animate-5 {
        animation: 1.5s 0.9s bannerFadeInLeft both;
      }
      .b-animate-6 {
        animation: 1.5s 0.9s bannerFadeInRight both;
      }
    }

    .slick-dots {
      bottom: 30px;
      li {
        background: var(--main-color);
        border-radius: 2px;
        width: 12px;
        height: 12px;
        opacity: 0.6;
        &.slick-active {
          background: var(--main-color);
          opacity: 1;
        }
        button {
          &::before {
            color: transparent;
          }
        }
      }
    }
  }
}
.banner-slider .owl-item.active .b-animate-1 {
  animation: 1.5s 0.2s fadeInLeft both;
}

.banner-slider .owl-item.active .b-animate-2 {
  animation: 1.5s 0.4s fadeInLeft both;
}

.banner-slider .owl-item.active .b-animate-3 {
  animation: 1.5s 0.6s fadeInLeft both;
}

.banner-slider .owl-item.active .b-animate-4 {
  animation: 1.5s 0.8s fadeInLeft both;
}

.banner-slider .owl-item.active .b-animate-5 {
  animation: 1.5s 0.9s fadeInLeft both;
}

.home-area {
  position: relative;
  z-index: 0;
  width: 100%;
  height: calc(100vh - 80px);

  .transport-slider-area-wrapper {
    position: relative;
    .transport-slider {
      .transport-slider-item {
        position: relative;
        width: 100%;
        height: calc(100vh - 40px);
        display: flex;
        align-items: center;
        background-repeat: no-repeat;
        background-size: cover;
        &::before {
          position: absolute;
          content: "";
          width: 100%;
          height: 100%;
          top: 0;
          background: rgba(0, 0, 0, 0.3);
        }
        .header-inner-wrap {
          height: 100%;
          display: flex;
          align-items: center;
          .header-inner {
            position: relative;
            margin-left: 5%;
            margin-top: -7%;
            .sub-title {
              font-size: 18px;
              line-height: 28px;
              font-weight: 700;
              color: var(--active-color);
              position: absolute;
              font-family: Raleway;
            }
            .title {
              font-size: 65px;
              line-height: 80px;
              font-weight: 600;
              margin-bottom: 60px;
              color: var(--secondary-color);
            }
          }
        }
        .header-image {
          .header-slide-image {
            &.image1 {
              margin-top: 18%;
              right: 4%;
            }
          }
        }
      }
    }
  }
  .slider-bottom-transport-wrapper {
    position: absolute;
    bottom: 10px;
    width: 100%;
    .transport-slider-nav-item {
      position: relative;
      a {
        font-size: 18px;
        text-align: center;
        display: flex;
        color: #181a2a;
        &:hover {
          color: #181a2a;
        }
        i {
          padding-right: 15px;
          &::before {
            font-size: 50px;
          }
        }
      }
    }
  }

  .transport-slider-indicator {
    .transport-slider-nav {
      .transport-slider-nav-active {
        &.slick-current.slick-active {
          .transport-slider-nav-item {
            a {
              color: var(--active-color);
              font-weight: 700;
            }
          }
        }
      }
      .transport-slider-nav-item {
        margin: 10px auto;
        display: block;
        cursor: pointer;
      }
    }
  }
  .transport-slider-progress {
    position: absolute;
    height: 300px;
    background: var(--secondary-color);
    z-index: 9;
    width: 2px;
    top: 30.5%;
    left: 8%;
    .transport-slider-progress-is {
      position: absolute;
      width: 3px;
      background: var(--active-color);
      right: 0;
      top: 0;
    }
  }
  .transport-slider-controls {
    position: absolute;
    bottom: 20px;
    width: 100%;
  }
  //slick - slider

  .slick-list {
    overflow: visible;
  }
  .slick-dots li button {
    font-size: 20px;
    line-height: 25px;
    cursor: pointer;
    color: transparent;
    border: 0;
    outline: none;
    background: transparent;
    color: #fff !important;
    &::before {
      content: "0";
    }
    &:hover {
      color: #181a2a !important;
      font-weight: 600;
    }
  }
  .slick-dots {
    z-index: -1;
    margin: 0;
    padding: 0;
    list-style: none;
    z-index: -1;
    li.slick-active button {
      color: #f14b4b !important;
      font-size: 30px;
      line-height: 35px;
      font-weight: 600;
    }
  }
  .slick-dots li {
    position: relative;
    display: inline-flex;
    margin: 0 5px;
    padding: 0;
    cursor: pointer;
  }

  .slick-dotted.slick-slider {
    margin-bottom: 0px;
  }
  .slider-nav-item.nav-active {
    box-shadow: 0px 5px 5px 0 rgba(0, 0, 0, 0.1);
  }
  .slick-prev {
    display: none !important;
  }
  .slick-next {
    color: transparent;
    border: 0;
    outline: none;
    background: transparent;
    position: absolute;
    left: -50px;
    top: 5px;
    cursor: pointer;
    i {
      color: #fff;
      width: 40px;
      height: 40px;
      line-height: 40px;
      background-color: #f14b4b;
      border-radius: 50%;
      text-align: center;
      display: inline-block;
      border-radius: 50%;
      &:hover {
        color: #181a2a;
        background-color: #fff;
      }
      &::before {
        font-size: 20px;
        display: contents;
      }
    }
  }
  &.home-v2 {
    position: relative;
    z-index: 9;
    margin-top: 80px;
    .header-bg {
      background-size: cover;
      position: relative;
      width: 100%;
      display: flex;
      align-items: center;
      .header-inner-wrap {
        height: 100%;
        display: flex;
        align-items: center;
        .header-inner {
          margin-left: 0;
          margin-top: 0;
          background-color: rgba(#000000, 0.5);
          padding: 40px;
          margin-right: 15%;
          position: relative;
          &::before {
            content: "";
            height: 100%;
            width: 10px;
            background-color: var(--active-color);
            position: absolute;
            left: 0px;
            top: 0;
          }
          .sub-title {
            font-size: 18px;
            line-height: 28px;
            font-weight: 700;
            margin-bottom: 20px;
            color: var(--active-color);
            font-family: Raleway;
          }
          .title {
            font-size: 50px;
            line-height: 60px;
            font-weight: 600;
            margin-bottom: 20px;
            color: var(--secondary-color);
          }
          .btn-wrapper {
            margin-top: 40px;
            a {
              padding: 15px 20px;
              background: var(--main-color);
              color: #fff;
            }
          }
        }
      }
    }
    .header-slider .header-bg {
      position: relative;
      &::before {
        position: absolute;
        content: "";
        width: 100%;
        height: 100%;
        top: 0;
        background: rgba(0, 0, 0, 0.3);
      }
    }
    .animated {
      transition: all 0.5s ease;
    }
    .header-slider [data-animation-in] {
      opacity: 0;
      animation-duration: 1s;
      transition: opacity 0.5s ease 0.3s;
    }
    .header-bottom {
      width: 100%;
      position: absolute;
      bottom: 0px;
      z-index: 999;
      .home-v2-contact {
        position: relative;
        margin: 7% 0% 0% 14%;
        .social-link {
          margin: 0;
          padding: 0;
          list-style: none;
          li {
            display: inline-block;
            margin: 0 30px;
            &:first-child {
              margin-left: 10px;
            }
            a {
              display: block;
              width: 35px;
              height: 35px;
              border-radius: 50%;
              text-align: center;
              line-height: 35px;
              font-size: 16px;
              color: #fff;
            }
            &:hover {
              a {
                color: var(--main-color);
              }
            }
          }
        }
      }
      .controler-wrapper {
        position: absolute;
        width: 100%;
        height: auto;
        right: 10px;
        bottom: 50px;
        .slick-prev {
          display: none !important;
        }
        // .slick-next{
        //   color: transparent;
        //   border: 0;
        //   outline: none;
        //   background: transparent;
        //   position: absolute;
        //   left: -50px;
        //   top: 5px;
        //   cursor: pointer;
        //   i{
        //     color: var(--secondary-color);
        //     width: 40px;
        //     height: 40px;
        //     line-height: 40px;
        //     background-color:var(--active-color);
        //     border-radius: 50%;
        //     text-align: center;
        //     display: inline-block;
        //     border-radius: 50%;
        //     &:hover{
        //       color: var(--active-color);
        //       background-color:var(--secondary-color);
        //     }
        //     &::before {
        //       font-size: 20px;
        //       display: contents;
        //     }
        //   }
        // }
        .number-wrap {
          text-align: right;
          position: absolute;
          right: 50px;
          bottom: -12px;
        }
        .active-controler {
          font-weight: 300;
          font-size: 40px;
          color: var(--secondary-color);
          &::before {
            content: "0";
          }
        }
        .separator {
          font-size: 30px;
          color: var(--secondary-color);
          top: 10px;
          position: absolute;
        }
        .total-controler {
          left: 60px;
          position: absolute;
          top: 14px;
          color: var(--secondary-color);
          &::before {
            content: "0";
          }
        }
        .progressbar {
          width: 100%;
          float: left;
          height: 5px;
          position: relative;
          bottom: -70px;
          .home-slider-progress {
            background-color: var(--button-color);
            position: absolute;
            width: 100%;
            height: 5px;
            bottom: 20px;
          }
          .home-slider-progress-active {
            background-color: var(--active-color);
            position: absolute;
            width: 33.33%;
            height: 5px;
            bottom: 20px;
          }
        }
      }
    }
    .header-sm-slider {
      justify-content: flex-end;
      overflow: hidden;
      .custom-thumb {
        padding: 0 5px;
      }

      img {
        width: 250px;
        height: 140px;
        margin: 0px;
        margin-top: 19%;
        @media only screen and (max-width: 1400px) {
          width: 200px;
          height: 120px;
          margin-top: 19%;
        }
        @media only screen and (max-width: 1200px) {
          width: 140px;
          height: 120px;
          margin-top: 19%;
        }
      }

      .slick-current img {
        width: 280px;
        height: 200px;
        margin: 0px;
        position: relative;
        bottom: 0;
        @media only screen and (max-width: 1400px) {
          width: 250px;
          height: 150px;
        }
      }

      .slick-prev {
        display: none !important;
      }
      .slick-next {
        display: none !important;
      }
    }
    .slick-prev,
    .slick-next {
      border-radius: 50%;
      text-align: center;
      z-index: 9999;
      top: 86%;
      left: 47%;
    }
  }
  &.home-v3 {
    position: relative;
    z-index: 9;
    margin-top: 80px;
    .header-bg {
      background-size: cover;
      position: relative;
      width: 100%;
      display: flex;
      align-items: center;
      .header-inner-wrap {
        height: 100%;
        display: flex;
        align-items: center;
        .header-inner {
          margin-left: 0;
          margin-top: 0;
          position: relative;

          .sub-title {
            font-size: 18px;
            line-height: 28px;
            font-weight: 700;
            margin-bottom: 20px;
            color: var(--active-color);
            font-family: Raleway;
          }
          .title {
            font-size: 50px;
            line-height: 60px;
            font-weight: 600;
            margin-bottom: 20px;
            color: var(--secondary-color);
          }
          .sub-title {
            font-size: 16px;
            color: var(--secondary-color);
          }
          .btn-wrapper {
            margin-top: 40px;
            a {
              padding: 15px 20px;
              background: var(--main-color);
              color: #fff;
            }
          }
        }
      }
      .right-wrapper {
        display: flex;
        align-items: center;
        width: 100%;
        height: 100%;
        margin-left: 50px;
        .inner {
          background: rgba(0, 0, 0, 0.5);
          padding: 30px;
          border-radius: 5px;
          border-left: 10px solid var(--main-color);
        }
        h2 {
          font-size: 32px;
          text-transform: capitalize;
          color: var(--secondary-color);
        }
        .list {
          ul {
            li {
              font-size: 16px;
              color: var(--secondary-color);
            }
          }
        }
      }
    }
    .slick-slide.slick-active {
      .header-inner-wrap {
        .header-inner {
          .sub-title {
            animation: 1.5s 0.6s bannerFadeInLeft both;
          }
          .title {
            animation: 1.5s 0.4s bannerFadeInLeft both;
          }

          .btn-wrapper {
            animation: 1.5s 0.8s bannerFadeInLeft both;
          }
        }
      }
      .right-wrapper {
        animation: 1.5s 0.4s bannerFadeInRight both;
      }
    }
    .slick-next {
      color: rgba(255, 255, 255, 0);
      border: 0;
      outline: none;
      background: transparent;
      position: absolute;
      right: 30px;
      top: 50%;
      cursor: pointer;
      bottom: 0;
      transform: translateY(-50%);
      left: auto;
      display: block !important;
      z-index: 9;
      &::before {
        font-size: 40px;
      }
    }
    .slick-prev {
      color: rgba(255, 255, 255, 0);
      border: 0;
      outline: none;
      background: transparent;
      position: absolute;
      right: auto;
      left: 30px;
      top: 50%;
      cursor: pointer;
      bottom: 0;
      transform: translateY(-50%);
      display: block !important;
      z-index: 9;
      &::before {
        font-size: 40px;
      }
    }
  }
}

.header-height {
  height: calc(100vh - 80px);
}

/* Filter Bar */
.filter-bar {
  display: flex;
  justify-content: center;
  align-items: start;
  gap: 50px;
  padding: 15px;
  margin: 0 auto;
  max-width: 100%; 
  flex-wrap: wrap; 
  border: 1px solid #f66711;
  border-radius: 20px;
  width: 800px;
  height: 100px;
}

.filter-bar select {
  padding: 10px;
  font-size: 16px;
  background-color: #fff;
  font-weight: bold;
  border: none;
  border-left: 2px solid #ccc;
  outline: none;
  border-radius: 0;
  flex: 1; 
  min-width: 120px; 
  max-width: 200px; 
}

.filter-bar select:not(:last-child) {
  margin-right: 0; 
}

.filter-bar select:focus {
  border-bottom: 2px solid #007bff;
}

/* Available Data Section */
.availableData {
  display: flex;
  justify-content: center;
  flex-direction: column;
  align-items: center;
  padding-top: 200px; 
}

@media (max-width: 768px) {

  .availableData {
    padding-top: 300px;
  }
}

@media (max-width: 480px) {

  .availableData {
    padding-top: 500px;
  }
}


.availableData h2 {
  font-size: 40px; 
  margin-bottom: 10px;
}

.availableData p {
  text-align: center;
  font-size: 16px;
  color: #666;
  margin-top: 5px;
}

.noResult {
  display: flex;
  justify-content: center;
}

/* Responsive Adjustments */
@media (max-width: 768px) {
  /* Filter Bar */
  .filter-bar {
    gap: 15px; 
    padding: 10px;
    max-width: 90%; 
  }

  .filter-bar select {
    font-size: 14px; 
    padding: 8px;
    min-width: 100px; 
    max-width: 150px; 
  }

  /* Available Data Section */
  .availableData h2 {
    font-size: 20px; 
  }

  .availableData p {
    font-size: 14px; 
    color: #444; 
  }
}

@media (max-width: 480px) {
  /* Filter Bar */
  .filter-bar {
    flex-direction: column; 
    align-items: stretch; 
    gap: 10px;
    padding: 10px;
    max-width: 100%; 
  }

  .filter-bar select {
    font-size: 12px; 
    padding: 6px;
    min-width: 100%; 
  }

  /* Available Data Section */
  .availableData h2 {
    font-size: 18px; 
  }

  .availableData p {
    font-size: 12px; 
  }
}
.view-all-page {
  padding: 20px;
  text-align: center;
}

.truck-list {
  display: grid;
  grid-template-columns: repeat(2, 1fr); 
  gap: 20px;
  margin-top: 20px;
  justify-content: center; 
  padding-left: 200px;
}

.truck-card {
  border: 1px solid #ddd;
  border-radius: 8px;
  padding: 20px; 
  background-color: #fff;
  box-shadow: 0 2px 4px rgba(0, 0, 0, 0.1);
  width: 100%; 
  max-width: 600px; 
}

.truck-card p
{
  text-align: left;
}

.truck-card h2 {
  font-size: 18px;
  margin-bottom: 10px;
  color: #333;
}

.truck-card p {
  margin: 5px 0;
  font-size: 14px;
  color: #555;
}

@media (max-width: 768px) {
  .truck-list {
    grid-template-columns: 1fr;
    gap: 15px;
  }

  .truck-card {
    padding: 15px;
    max-width: 90%;
    margin: 0 auto;
  }

  .truck-card h2 {
    font-size: 16px; 
  }

  .truck-card p {
    font-size: 13px; 
  }
}


@media (max-width: 480px) {
  .truck-card {
    padding: 10px;
    max-width: 100%;
  }

  .truck-card h2 {
    font-size: 14px;
  }

  .truck-card p {
    font-size: 12px;
  }
}



.btn-back
{
  margin-bottom: 30px;
  border-radius: 30px;
  height: 50px;
  align-items: center !important;
  justify-content: center !important;
  display: flex !important;
}
