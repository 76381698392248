/*----------------------------------------------
    # Nav bar
----------------------------------------------*/
.navbar-top {
  background: #080c24;
  position: relative;
  margin-bottom: 68px;
  padding: 16.5px 0;
  &:after {
    content: "";
    position: absolute;
    right: 0;
    top: 0;
    width: 66.8%;
    background: url("~/public/assets/img/shape/topbar-bg.png");
    background-position: 0 0px;
    background-size: 165% 100%;
    height: 100%;
    z-index: -1;
  }
  .nav-phone-wrap {
    background: url("~/public/assets/img/shape/nav-contact-bg.png");
    background-position: -38px 0px;
    background-size: 112% 100%;
    background-repeat: no-repeat;
    display: inline-block;
    position: absolute;
    top: 90px;
    color: #fff;
    padding: 12.5px 135px 9.5px 115px;
    left: -70px;
    font-family: var(--heading-font);
    font-size: 14px;
    i,
    svg {
      margin-right: 5px;
    }
  }
  .logo {
    position: absolute;
    left: -70px;
    top: 0;
    width: 530px;
    .main-logo {
      display: inline-block;
    }
  }
  ul {
    margin: 0;
    padding: 0;
    list-style: none;
    &.topbar-left {
      margin-left: 369px;
      li {
        + li {
          margin-left: 11px;
        }
        i,
        svg {
          margin-right: 7px;
          color: var(--main-color);
        }
      }
    }
    &.topbar-right {
      li {
        + li {
          margin-left: 17px;
        }
      }
    }
    li {
      display: inline-block;
      color: #fff;
      font-size: 14px;
      a {
        color: #fff;
        &:hover {
          color: var(--main-color);
        }
      }
    }
  }
}
.navbar-area {
  z-index: 99;
  position: absolute;
  width: 100%;
  .paddingnobottom{
    padding-bottom: 0px !important;
  }
  .nav-container {
    background-color: white;
    padding: 17px 0px;
    transition: all 0.4s;
    padding-left: 0px !important;
    padding-right: 0px !important;
    .btn-transparent {
      font-size: 13px;
      font-weight: 700;
    }
    .navbar-collapse {
      
      .navbar-nav {
        display: block;
        width: 100%;
        text-align: left;

        li {
          a {
            color: #333333;
            font-weight: 500;
            font-size: 20px;
          }
          &:hover a {
            color: #fb493d;
          }
          + li {
            margin-left: 15px;
          }
          display: inline-block;
          font-weight: 500;
          line-height: 50px;
          text-transform: capitalize;
          font-size: large;
          &.menu-item-has-children {
            position: relative;
            z-index: 0;
            padding-right: 14px;
            &:after {
              content: "";
              position: absolute;
              right: -1px;
              top: 26px;
              height: 2px;
              width: 10px;
              background: var(--heading-color);
              transform: translateY(-50%);
              transition: all 0.3s ease-in;
              z-index: -1;
            }
            &:before {
              content: "";
              position: absolute;
              right: 3px;
              top: 50%;
              height: 10px;
              width: 2px;
              background: var(--heading-color);
              transform: translateY(-50%);
              transition: all 0.3s ease-in;
              margin-top: 1px;
              z-index: -1;
            }
            &:hover {
              @include transition(all, 0.4s, ease);
              &:before {
                opacity: 0;
              }
              &:after {
                background: var(--main-color);
              }
            }
            &:hover > .sub-menu {
              visibility: visible;
              opacity: 1;
            }
            .sub-menu {
              position: absolute;
              text-align: left;
              min-width: 210px;
              margin: 0;
              padding: 0;
              list-style: none;
              left: 0;
              top: 100%;
              box-shadow: 0 0 15px 0 rgba(#000, 0.05);
              background-color: $color-white;
              z-index: 9;
              overflow: hidden;
              visibility: hidden;
              opacity: 0;
              @include transition(all, 0.4s, ease);
              border-radius: 0;
              &.border-bt0 {
                border-bottom: 0px !important;
              }
              li {
                display: block;
                margin-left: 0;
                line-height: 22px;
                font-size: 15px;
                @include transition(all, 0.4s, ease);
                border-bottom: 1px solid #f5f5f5;
                a {
                  display: block;
                  padding: 10px 20px;
                  white-space: nowrap;
                  transition: all 0.3s;
                  color: #050a30;
                  font-size: 14px;
                  font-weight: 500;
                  @include transition(all, 0.4s, ease);
                  position: relative;
                }
                &:hover {
                  background: #181a2a;
                  a {
                    color: #fff;
                    &:before {
                      visibility: visible;
                      opacity: 1;
                    }
                  }
                }
                &:last-child {
                  border-bottom: 0;
                }
              }
              .menu-item-has-children {
                position: relative;
                z-index: 0;
                padding-right: 0px;
                > .sub-menu {
                  left: 100%;
                  top: 20px;
                  .sub-menu {
                    .sub-menu {
                      left: auto;
                      right: 100%;
                    }
                  }
                }
                &:hover > .sub-menu {
                  visibility: visible;
                  opacity: 1;
                  li {
                    &:hover {
                      &:before {
                        color: $color-white;
                      }
                    }
                  }
                }
              }
            }
          }
          &:last-child {
            margin-right: 0;
          }
        }
        > li {
          margin-right: 10px;
        }
      }
    }
  }
}
.navbar-area-1 {
  padding: 0;
  top: 60px;
  .nav-container {
    padding: 7px 0px 27px;
  }
  .nav-container .navbar-collapse .navbar-nav {
    padding-left: 368px;
  }
  &:after {
    content: "";
    position: absolute;
    right: 0;
    top: 0;
    background-image: url("~/public/assets/img/shape/nav-bar.png");
    height: 100%;
    width: 74.5%;
    z-index: -1;
    background-size: 142% 80%;
    background-position: 4px 0px;
    background-repeat: no-repeat;
  }
  .logo {
    display: none;
    a {
      font-weight: 700;
      font-size: 24px;
      color: #fff;
      img {
        height: auto;
      }
    }
  }
}
.MuiDataGrid-columnHeaders {
  background-color: var(--main-color);
  color: white;
  font-weight: 800;
}
.navbar-area-2 {
  padding: 0;
  margin-top: 0px;
  // &:after {
  //   content: "";
  //   position: absolute;
  //   right: 0;
  //   top: 0;
  //   background-image: url("~/public/assets/img/shape/nav-bar-2.png");
  //   height: 100%;
  //   width:30%;
  //   max-width: 300px;
  //   z-index: -1;
  //   background-size: 100% 100%;
  //   background-position: 0px 0px;
  //   background-repeat: no-repeat;
  // }
  .logo {
    position: absolute;
    left: 70px;
    top: 20px;
    // top: -40px;
    .logo-2 {
      display: none;
      img{
        height: 60px;
      }
      
    }
  }
  .rightTextNav {
    position: absolute;
    right: 350px;
    top: 30px;
    align-items: center;
    display: flex;
    justify-content: center;
    color: black;
  }
  .rightTextNav2 {
    position: absolute;
    right: 250px;
    top: 30px;
    align-items: center;
    display: flex;
    justify-content: center;
    color: black;
  }

  .rightButtonNav {
    position: absolute;
    right: 70px;
    top: 20px;
    display: flex;
    justify-content: center;
    align-items: center;
    // top: -40px;
  }
  .nav-container {
    padding: 80px 50px 20px;
    max-width: 100%;
  }
  &.sticky-active .nav-container .logo {
    position: initial;
    .logo-1 {
      display: none;
    }
    .logo-2 {
      display: block;
    }
  }
}

.navbar-area-1.navbar-area-3 {
  top: 40px;
  padding: 0;
  // background: #f14b4b;
  .nav-container {
    padding: 12px 0px !important;
    .navbar-collapse .navbar-nav {
      display: flex;
      justify-content: flex-end;
    }
  }
  &:after {
    content: "";
    position: inherit;
    right: 0;
    top: 0;
    background: #fff !important;
    background-image: none !important;
    height: 100%;
    width: 100%;
    z-index: -1;
    background-size: 142% 80%;
    background-position: 4px 0px;
    background-repeat: no-repeat;
  }

  .logo {
    display: block;
    img {
      width: 135px;
    }
  }
}
.navbar-area-1.navbar-area-4 {
  top: 0;
  padding: 0;
  // background: #f14b4b;
  .nav-container {
    padding: 12px 0px !important;
    .navbar-collapse .navbar-nav {
      display: flex;
      justify-content: flex-end;
    }
  }
  &:after {
    content: "";
    position: inherit;
    right: 0;
    top: 0;
    background: #fff !important;
    background-image: none !important;
    height: 100%;
    width: 100%;
    z-index: -1;
    background-size: 142% 80%;
    background-position: 4px 0px;
    background-repeat: no-repeat;
  }

  .logo {
    display: block;
    img {
      width: 135px;
    }
  }
}

//sticky-style here
.sticky-active {
  -webkit-animation: 300ms ease-in-out 0s normal none 1 running fadeInDown;
  animation: 300ms ease-in-out 0s normal none 1 running fadeInDown;
  left: 0;
  position: fixed;
  top: 0;
  width: 100%;
  z-index: 999;
  -webkit-box-shadow: 0 10px 20px 0 rgb(46 56 220 / 5%);
  box-shadow: 0 10px 20px 0 rgb(46 56 220 / 5%);
  background: #fff;
  margin: 0;
  .nav-container {
    padding: 12px 12px;
    .logo {
      display: block;
      width: 150px;
    }
    .navbar-collapse .navbar-nav {
      padding-left: 0;
      text-align: end;
    }
  }
  &.navbar-area-2:after {
    display: none;
  }
}
// .navbar-nav {
//   opacity: 0;
//   margin-right: -30px;
//   visibility: hidden;
//   transition: all 0.3s ease 0s;
// }
.menu-open {
  opacity: 1;
  margin-right: 0;
  visibility: visible;
}
.bar1 {
  width: 32px;
  height: 2px;
  margin-bottom: 5px;
  position: absolute;
  background: $color-white;
  z-index: 9999;
  top: 10px;
  right: -5px;
}
.bar2 {
  width: 24px;
  height: 2px;
  margin-bottom: 5px;
  position: absolute;
  background: $color-white;
  z-index: 9999;
  top: 17px;
  right: -5px;
}
.bar3 {
  width: 18px;
  height: 2px;
  margin-bottom: 5px;
  position: absolute;
  background: $color-white;
  z-index: 9999;
  top: 24px;
  right: -5px;
}
.responsive-mobile-menu button:focus {
  outline: none;
  border: none;
}

/**************** nav-right-part *************/
.nav-right-part {
  display: flex;
  align-items: center;
  .btn {
    color: #fff;
    padding: 0 27px;
  }
  a {
    margin-left: 30px;
    color: var(--heading-color);
    display: inline-block;
  }
  .cart-btn {
    position: relative;
    span {
      position: absolute;
      right: -10px;
      top: -5px;
      height: 22px;
      width: 22px;
      border-radius: 50%;
      background: #fb493d;
      text-align: center;
      font-size: 13px;
      line-height: 22px;
    }
  }
  .menu-bar {
    background: #fff;
    border-radius: 50px;
    height: 50px;
    width: 50px;
    display: inline-flex;
    line-height: 50px;
    justify-content: center;
    align-items: center;
    margin-left: 42px;
  }
}
.nav-right-part-mobile {
  display: none;
}

/**************** sidebar-menu *************/
.sidebar-menu {
  width: 0;
  position: fixed;
  right: 0;
  top: 0;
  height: 100vh;
  background-color: #fff;
  z-index: 999;
  overflow-y: auto;
  transition: 0.5s;
  opacity: 0;
  visibility: hidden;
}
.sidebar-menu .sidebar-inner {
  position: relative;
  top: 150px;
  opacity: 0;
  visibility: hidden;
  transition: 0.3s;
  padding: 50px 40px;
  .thumb {
    margin-bottom: 15px;
  }
  p {
    margin-bottom: 28px;
  }
  .sidebar-address {
    margin-bottom: 35px;
    ul {
      margin: 0;
      padding: 0;
      li {
        list-style: none;
        margin-bottom: 4px;
        &:last-child {
          margin-bottom: 0;
        }
        i {
          margin-right: 6px;
          width: 25px;
        }
      }
    }
  }
  .sidebar-subscribe {
    position: relative;
    margin-bottom: 40px;
    input {
      width: 100%;
      border: 1px solid #d6dadf;
      height: 42px;
      padding: 0 45px 0 12px;
    }
    button {
      position: absolute;
      right: 0;
      top: 0;
      height: 42px;
      width: 42px;
      background: #97b3d0;
      transition: 0.4s;
      border: 0;
      color: var(--heading-color);
      cursor: pointer;
      &:hover {
        background: var(--main-color);
        color: #fff;
      }
    }
  }
  .social-media li a {
    background: #ced4da;
  }
  .social-media li a:hover {
    background: var(--main-color);
    color: #fff;
  }
}
.sidebar-menu .sidebar-menu-close {
  background-color: #043381;
  border: 0;
  position: absolute;
  top: 0;
  right: 0;
  color: #fff;
  width: 50px;
  height: 50px;
  line-height: 50px;
  cursor: pointer;
  z-index: 999;
}
.sidebar-menu.active {
  width: 360px;
  opacity: 1;
  visibility: visible;
}
.sidebar-menu.active .sidebar-inner {
  top: 0;
  opacity: 1;
  visibility: visible;
  transition: all 0.7s ease-out 0.8s;
}

@media only screen and (max-width: 991px) {
  .navbar-area
  {
    display: none;
  }
  .navbar-area .nav-container {
    padding: 15px;
  }
  .nav-right-part {
    margin-right: 50px;
  }
  .nav-right-part .btn .right {
    padding-left: 5px;
    font-size: 13px;
  }
  .navbar-area .nav-container .navbar-collapse .navbar-nav > li {
    margin-right: 0;
    padding-right: 0;
  }
  .navbar-area {
    .nav-container {
      position: relative;
      z-index: 0;
      .navbar-toggler {
        padding: 0px;
      }
      .navbar-collapse {
        background-color: var(--footer-color);
        color: white;
        margin-top: 13px;

        .navbar-nav {
          display: block;
          margin-top: 20px;
          li {
            a {
              display: block;
            }
            display: block;
            text-align: left;
            line-height: 30px;
            padding: 10px 0;
            border-bottom: 1px solid rgba(0, 0, 0, 0.1);
            &:last-child {
              border-bottom: none;
            }
            + li {
              margin-left: 0;
            }
            &.menu-item-has-children {
              &:before {
                top: 26px;
                right: 3px !important;
              }
              .sub-menu {
                position: initial;
                display: block;
                width: 100%;
                border-top: none;
                box-shadow: none;
                margin-left: 0;
                padding-bottom: 0;
                overflow: hidden;
                max-height: 0;
                overflow-y: scroll;
                background-color: transparent;
                border-radius: 10px;
                padding: 0px;
                border-bottom: none;
                visibility: hidden;
                opacity: 0;
                transition: all 0.3s linear;
                &.active {
                  max-height: 300px;

                  visibility: visible;
                  opacity: 1;
                }

                .sub-menu .menu-item-has-children:before {
                  content: "\f107";
                }
                li {
                  &.menu-item-has-children {
                    &:hover {
                      &:before {
                        top: 30px;
                        color: $color-white;
                      }
                    }
                  }
                  padding: 0;
                  + li {
                    border-top: none;
                  }
                }
              }
            }
          }
        }
      }
    }
  }
}


.sopen {
  display: block;
}

//toggle button css
.toggle-btn {
  left: auto;
  right: -10px;
  position: absolute;
  top: 10px;
  width: 40px;
  height: 40px;
  transition-duration: 0.5s;
  border: 0;
  background: transparent;

  .icon-left {
    transition-duration: 0.5s;
    position: absolute;
    height: 2px;
    width: 11px;
    top: 18px;
    background-color: #fff;
    left: 7px;
    &:before {
      transition-duration: 0.5s;
      position: absolute;
      width: 11px;
      height: 2px;
      background-color: #fff;
      content: "";
      top: -7px;
      left: 0;
    }

    &:after {
      transition-duration: 0.5s;
      position: absolute;
      width: 11px;
      height: 2px;
      background-color: #fff;
      content: "";
      top: 7px;
      left: 0;
    }

    &:hover {
      cursor: pointer;
    }
  }

  .icon-right {
    transition-duration: 0.5s;
    position: absolute;
    height: 2px;
    width: 11px;
    top: 18px;
    background-color: #fff;
    left: 18px;

    &:before {
      transition-duration: 0.5s;
      position: absolute;
      width: 11px;
      height: 2px;
      background-color: #fff;
      content: "";
      top: -7px;
      left: 0;
    }

    &:after {
      transition-duration: 0.5s;
      position: absolute;
      width: 11px;
      height: 2px;
      background-color: #fff;
      content: "";
      top: 7px;
      left: 0;
    }

    &:hover {
      cursor: pointer;
    }
  }

  &.open {
    .icon-left {
      transition-duration: 0.5s;
      background: transparent;

      &:before {
        transform: rotateZ(45deg) scaleX(1.4) translate(2px, 1px);
      }

      &:after {
        transform: rotateZ(-45deg) scaleX(1.4) translate(2px, -1px);
      }
    }

    .icon-right {
      transition-duration: 0.5s;
      background: transparent;

      &:before {
        transform: rotateZ(-45deg) scaleX(1.4) translate(-2px, 1px);
      }

      &:after {
        transform: rotateZ(45deg) scaleX(1.4) translate(-2px, -1px);
      }
    }
  }

  &:hover {
    cursor: pointer;
  }
}

@media only screen and (min-width: 992px) and (max-width: 1199px) {
  .navbar-area .nav-container .navbar-collapse .navbar-nav li {
    font-size: 16px;
  }
  .navbar-area .nav-container .navbar-collapse .navbar-nav li + li {
    margin-left: 5px;
  }
  .navbar-nav li {
    padding-right: 12px !important;
  }
}

@media only screen and (min-width: 768px) and (max-width: 991px) {
  .navbar-area .nav-container .navbar-collapse .navbar-nav li {
    font-size: 14px;
  }
  .navbar-area .nav-container .navbar-collapse.sopen {
    display: block;
  }
  .navbar-area .nav-container .navbar-collapse .navbar-nav li {
    padding-left: 20px;
    padding-right: 20px;
  }
}

@media only screen and (max-width: 1199px) {
  .margin-xlt-80 {
    margin-top: 0px;
  }
  .contact-widget .contact_info_list li.single-info-item .details {
    padding-left: 25px;
  }
}

@media only screen and (max-width: 991px) {
  .navbar-area .logo {
    padding-top: 0px !important;
  }


  .widget ul {
    text-align: left;
  }
  .navbar-collapse {
    background: $color-white;
    margin-top: 0px;
    width: 100%;
  }

  .navbar-area
    .nav-container
    .navbar-collapse
    .navbar-nav
    li.menu-item-has-children:before {
    right: 20px;
  }
  .navbar-area .nav-container .navbar-collapse .navbar-nav {
    margin-bottom: 20px;
  }
  .navbar-area {
    padding-bottom: 0px;
  }
  .bar1,
  .bar2,
  .bar3 {
    background: #fff;
  }
  .party-box-wrapper {
    padding: 50px 0px;
  }
  .party-box-wrapper .party-box-content h4 {
    font-size: 30px;
    line-height: 45px;
  }
  .footer-area .copyright-area-inner {
    padding: 20px;
  }

  .navbar-expand-lg .navbar-collapse {
    margin-top: 0px;
  }
  .contact-widget .contact_info_list li.single-info-item .details {
    padding-left: 25px;
  }
  .footer-area .footer-top .widget.widget_nav_menu ul li a {
    font-size: 14px;
  }
  .navbar-area
    .nav-container
    .navbar-collapse
    .navbar-nav
    li.menu-item-has-children
    .sub-menu {
    padding: 0 0 0 20px;
  }
  .navbar-area
    .nav-container
    .navbar-collapse
    .navbar-nav
    li.menu-item-has-children
    .sub-menu
    li
    a {
    padding: 12px 0;
  }
  .navbar-area
    .nav-container
    .navbar-collapse
    .navbar-nav
    li.menu-item-has-children
    .sub-menu
    li:last-child
    a {
    padding-bottom: 3px;
  }
  .navbar-area
    .nav-container
    .navbar-collapse
    .navbar-nav
    li.menu-item-has-children
    .sub-menu
    li
    a:hover
    i {
    opacity: 0;
    margin-right: -18px;
  }
  .nav-right-part-mobile {
    display: block;
  }
  .nav-right-part-desktop {
    display: none;
  }
  .navbar-area
    .nav-container
    .navbar-collapse
    .navbar-nav
    li.menu-item-has-children
    .sub-menu
    li:hover {
    background: none;
  }
  .navbar-area .nav-container .navbar-collapse .navbar-nav li a {
    color: white
  }
  .navbar-area-1.navbar-area-3 .nav-container .navbar-collapse .navbar-nav {
    li {
      a {
        color: #333333;
        &:hover {
          color: var(--main-color);
        }
      }
      &:hover {
        a {
          color: var(--main-color);
        }
      }
      &.menu-item-has-children .sub-menu li:hover a {
        color: var(--main-color);
      }
    }
  }
  .navbar-area-1.navbar-area-4 .nav-container .navbar-collapse .navbar-nav {
    li {
      a {
        color: #333333;
        &:hover {
          color: var(--main-color);
        }
      }
      &:hover {
        a {
          color: var(--main-color);
        }
      }
      &.menu-item-has-children .sub-menu li:hover a {
        color: var(--main-color);
      }
    }
  }
}

/* Tablet Layout wide: 767px. */
@media only screen and (max-width: 767px) {
  .logo-wrapper.mobile-logo {
    display: block;
    width: 100%;
  }
  .responsive-mobile-menu {
    display: block;
    width: 100%;
    position: relative;
  }

  .responsive-mobile-menu .navbar-toggler {
    position: absolute;
    left: calc(100% - 130px);
    top: 10px;
  }
  .table-responsive {
    display: block !important;
  }
  .btn-custom-default,
  .btn-custom-white {
    font-size: 14px;
    line-height: 33px;
    padding: 6px 20px;
  }
  .navbar-area .logo {
    padding-top: 0px !important;
  }
}

/* medium tablet layout 599px */
@media only screen and (max-width: 575px) {
  .navbar-area .logo {
    padding-top: 10px;
  }
  .widget.footer-widget .subscribe-form.subscribe-form-style2 .form-control {
    padding: 15px 20px;
  }
  .widget.footer-widget .subscribe-form.subscribe-form-style2 .btn {
    padding: 15px 20px;
  }
  .search-popup .search-form {
    min-width: 350px;
  }
}

@media only screen and (max-width: 375px) {
  .btn-custom-default,
  .btn-custom-white {
    padding: 5px 18px;
  }
  .search-popup .search-form .form-group .form-control,
  .search-popup .search-form .submit-btn {
    height: 45px;
  }
  .search-popup .search-form {
    min-width: 300px;
  }
}

@media only screen and (max-width: 320px) {
  .search-popup .search-form {
    min-width: 265px;
  }
  .responsive-mobile-menu .navbar-toggler {
    left: calc(100% - 95px);
  }
}


